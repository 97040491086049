<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">
    <v-row no-gutters>

      <v-col cols="12" md="10" class="offset-md-1" v-if="!isSuccess">

        <Recaptcha ref="recaptcha" @verify="(response) => register(response)" />

        <v-row no-gutters>

          <v-col cols="12" class="mt-4">
            <h1 style="font-size: 1.3rem;" class="text-center w-100">{{$t('account.registration.title')}}</h1>
            <v-divider class="mt-2" />
          </v-col>

          <v-col cols="12" class="mt-8">
            <v-form>
              <v-row no-gutters>

                <!-- Firstname -->
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    dense
                    outlined
                    :label="$t('account.registration.labels.firstname')"
                    v-model="form.firstname"
                    maxlength="255"
                    :error-messages="errors.firstname"
                    @input="validateFirstname"/>
                </v-col>

                <!-- Name -->
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    dense
                    outlined
                    :label="$t('account.registration.labels.name')"
                    v-model="form.name"
                    maxlength="255"
                    :error-messages="errors.name"
                    @input="validateName"/>
                </v-col>

                <!-- Email -->
                <v-col cols="12" class="py-0">
                  <v-text-field
                    dense
                    outlined
                    :label="$t('account.registration.labels.email')"
                    @input="validateEmail"
                    maxlength="255"
                    :error-messages="errors.email"
                    v-model="form.email">
                    <template v-slot:prepend>
                      <img width="20" src="@/assets/icons/controls/user_off.svg" />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Password -->
                <v-col cols="12" class=" py-0">
                  <v-text-field
                    dense
                    outlined
                    maxlength="255"
                    type="password"
                    :error-messages="errors.password"
                    :label="$t('account.registration.labels.password')"
                    v-model="form.password">
                    <v-tooltip
                      slot="append-outer"
                      bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">far fa-question-circle</v-icon>
                      </template>
                      <template v-for="(policy) in $t('account.registration.tooltips.password_rules')">
                        <div> - {{policy}}</div>
                      </template>
                    </v-tooltip>

                    <template v-slot:prepend>
                      <img width="20" src="@/assets/icons/controls/password.svg" />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- CGU -->
                <v-col cols="12" class="py-0">
                  <v-row no-gutters>
                    <v-col cols="1">
                      <v-checkbox
                        v-model="form.acceptTerms"
                        color="primary"
                        :error="!!errors.acceptTerms" />
                    </v-col>
                    <v-col>
                      <p class="font-size-14" v-html="$t('account.registration.labels.accept-terms')" />
                    </v-col>
                  </v-row>
                </v-col>

                <!-- Alert bar -->
                <v-col cols="12">
                  <AlertBar />
                </v-col>

                <!-- Button -->
                <v-col cols="12" md="10" class="offset-md-1 text-center mt-6">
                  <v-btn
                    style="width: 200px; text-transform: none !important;border-radius: 10px !important;"
                    color="primary"
                    large
                    :disabled="!form.acceptTerms"
                    class="font-weight-bold"
                    :loading="isLoading"
                    @click="executeRecaptcha">
                    {{$t('account.registration.buttons.register')}}
                  </v-btn>
                </v-col>

                <!-- Social buttons -->
                <v-col cols="12">
                  <LoginSocialButtons />
                </v-col>

                <v-col cols="12" class="mt-12 mb-6">
                  <v-row justify="center" class="mx-2 mx-md-0" no-gutters>
                    <div>
                      <span>{{$t('account.registration.labels.already_registered')}}</span>
                      <router-link :to="{name: 'Login'}" class="ml-1">{{$t('account.registration.buttons.login')}}</router-link>
                    </div>

                    <!--div>
                      <span>{{$t('account.registration.labels.need_help')}}</span>
                      <span @click="showSupportDialog = true" class="btn-link ml-1 pointer">{{$t('account.registration.buttons.contact')}}</span>
                    </div-->
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

      </v-col>

      <!-- Is successful -->
      <v-col cols="12" v-else>
        <v-row align="start" class="mt-6">

          <!-- Icon -->
          <v-col cols="12" md="10" class="offset-md-1 text-center">
            <v-icon color="success" size="100px">far fa-check-circle</v-icon>
          </v-col>

          <!-- Text -->
          <v-col cols="12" md="10" class="offset-md-1 text-center mt-6">
            <p class="font-size-20 catamaran font-weight-bold" v-html="$t('account.registration.success')" />
          </v-col>

          <!-- Button -->
          <v-col cols="12" md="10" class="offset-md-1 text-center mt-6">
            <v-btn color="primary" large width="200" style="border-radius: 10px !important;" :to="{name: 'Login'}">
              <span class="catamaran font-weight-bold">{{$t('account.registration.buttons.login')}}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import cloneDeep from "clone-deep";
import validator from "validator";
import passwordPolicy from "password-policy";

export default {

  name: "Registration",

  components: {
    Recaptcha: () => import("@/components/Common/Recaptcha"),
    AlertBar: () => import("@/components/Common/AlertBar"),
    LoginSocialButtons: () => import("@/components/Account/LoginSocialButtons")
  },

  data() {
    return {
      form: {},

      default_form: {
        email: "", password: "", firstname: "", name: "", lang: "fr", acceptTerms: false
      },

      errors: {
        firstname: null, name: null, email: null, password: null, acceptTerms: null
      },

      currentState: null,
      isSuccess: false,
      isLoading: false,
      isPageLoading: false,
      showSupportDialog: false
    }
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t('account.registration.pageTitle'));

    this.form = cloneDeep(this.default_form);
    this.form.lang = this.$route.query.lang ? this.$route.query.lang : "fr";
  },

  methods: {

    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },

    register(recaptcha) {
      this.$store.commit("alert/hideAlert");
      this.validateFirstname();
      this.validateName();
      this.validateEmail();
      this.validatePassword();

      if (!Object.values(this.errors).some((el) => el !== null)) {
        this.isLoading = true;
        // Submit
        this.form["g-recaptcha-response"] = recaptcha;

        this.$http.post(`/map/users`, this.form).then(res => {
          this.form = cloneDeep(this.default_form);
          this.isSuccess = true;
        }).catch(err => {
          if (err.response && err.response.status === 400) {
            this.$store.commit("alert/showError", this.$t('account.registration.errors')[err.response.data.type]);
          } else this.$store.commit("alert/showError", this.$t('common.errors.500'));
        }).finally(() => {
          this.isLoading = false;
        })
      }
    },

    validateFirstname() {
      this.errors.firstname = (this.form.firstname) ? null : this.$t('common.errors.IS_REQUIRED');
    },

    validateName() {
      this.errors.name = (this.form.name) ? null : this.$t('common.errors.IS_REQUIRED');
    },

    validateEmail() {
      if (this.form.email) {
        this.errors.email = validator.isEmail(this.form.email) ? null : this.$t('account.registration.errors.EMAIL_INVALID');
      } else this.errors.email = this.$t('common.errors.IS_REQUIRED');
    },

    validatePassword() {
      if (this.form.password) {
        if (passwordPolicy.hasNumber(this.form.password, 1) && passwordPolicy.hasLowerCase(this.form.password, 1) &&
          passwordPolicy.hasUpperCase(this.form.password, 1) && this.form.password.length >=
          8) this.errors.password = null; else this.errors.password = this.$t(
          'account.registration.errors.INVALID_PASSWORD_POLICY');
      } else this.errors.password = this.$t('common.errors.IS_REQUIRED');
    }
  }
}
</script>

<style scoped>

</style>